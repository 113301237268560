<template>
  <div class="login">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="titulo rosa-padrao-cor">ENTRAR</p>
          <form @submit.prevent="onSubmit()">
            <div class="form-group">
              <label for="user">Nome de usuário</label>
              <input type="text" class="form-control" id="user" v-model="user" placeholder="Nome de usuário">
              <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
            </div>
            <div class="form-group mt-2">
              <label for="password">Senha</label>
              <input type="password" class="form-control" id="password" v-model="password" placeholder="Senha">
            </div>
            <div class="form-check mt-3">
              <input type="checkbox" class="form-check-input" id="remember" v-model="remember">
              <label class="form-check-label" for="remember">Lembrar dados</label>
            </div>
            <div class="form-group mt-2">
              <router-link to="/esqueci-a-senha">Esqueci a senha</router-link>
            </div>
            <button type="submit" class="btn btn-padrao btn-rosa mt-4">Entrar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login {
  .titulo {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
}
</style>

<script>
export default {
  name: 'Login ',
  data() {
    return {
      user: '',
      password: '',
      remember: false
    }
  },
  components: {
  },
  methods: {
    onSubmit() {
      console.log(this.user, this.password, this.remember)
    }
  }
}
</script>