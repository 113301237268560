<template>
    <div class="perfil">
        <p class="titulo">PROFILE</p>
        <div class="perfil-wrapper">
            <div class="imagem-wrapper">

            </div>
            <p class="usuario">USUARIO</p>
            <span class="ranking">RANKING</span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped lang="scss">
.perfil {
    width: 455px;
    padding: 53px 50px;
    .titulo {
        font-weight: 700;
    }
    .perfil-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .imagem-wrapper {
        width: 186px;
        height: 186px;
        background: #E42D80;
        border-radius: 100%;
        box-shadow: 0px 10px 50px rgba(228, 45, 128, 0.3);
        border: 20px solid #FFF;
        margin: 25px;
    }
    .usuario {
        font-weight: 700;
    }
    .ranking {
        font-weight: 600;
        font-size: 12px;
        color: #939AAC;
    }
}
</style>