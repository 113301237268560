<template>
  <div id="app">
    <div class="palaze" v-if="!isAdmin">
      <Header />
      <router-view class="py-5" />
      <Footer />
      <Social class="d-none d-xl-flex" />
    </div>
    <div class="admin" v-if="isAdmin">
      <div class="admin-wrapper">
        <HeaderAdmin />
        <router-view class="py-5" />
        <PerfilAdmin class="d-none d-lg-block" />
      </div>
      <Footer />
    </div>
  </div>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');    

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  word-wrap: break-word;

  .fonte-secundaria { 
    font-family: 'Roboto', sans-serif !important;
    letter-spacing: 1px;
  }

  .rosa-escuro-cor {
    color: #9B325A;
  }

  .rosa-padrao-cor {
    color: #E4157D;
  }

  .rosa-claro-cor {
    color: #F5556E ;
    
  }
  .btn-padrao {
    border-radius: 52px;
    padding: 15px 35px;
    font-family: 'Roboto', sans-serif !important;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 700;
  }
  .btn-rosa {
    background-color: #E4157D;
    color: #FFF;
  }
  .btn-branco {
    background-color: #FFF;
    color: #000;
  }
  .btn-preto {
    background-color: #000;
    color: #fff;
  }
  
  .ratio iframe {
    border-radius: 25px;
  }
}

@media (min-width: 1200px) {
  .admin-wrapper {
    display: flex;
    justify-content: center;
  }
}
</style>

<script>
import Header from '@/components/Header.vue'
import HeaderAdmin from '@/components/HeaderAdmin.vue'
import Footer from '@/components/Footer.vue'
import PerfilAdmin from '@/components/PerfilAdmin.vue'
import Social from '@/components/Social.vue'

export default {
  name: 'App',
  components: {
    Header,
    HeaderAdmin,
    Footer,
    PerfilAdmin,
    Social
  },
  computed: {
    isAdmin() {
      const regex = /admin(\/.*)?/;
      const str = this.$route.path;

      if ((regex.exec(str)) !== null) {
          return true
      }

      return false
    }
  }
}
</script>
