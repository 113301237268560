<template>
  <div class="header">
    <nav class="navbar navbar-expand-xl navbar-light">
      <div class="container-fluid header-wrapper">
        <router-link to="/admin" class="palaze-logo navbar-brand">
          <img src="../assets/palaze-black.svg" class="img-fluid" alt="Palaze logo">
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse fonte-secundaria" id="navbar">
          <p class="painel mt-5">PAINEL DE JOGADOR</p>
          <ul class="navbar-nav me-auto mt-4 mt-lg-0">
            <li class="nav-item">
              <router-link to="/admin/informacoes" class="nav-link">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.72333 10.39L7.66667 11.3333L11 8L7.66667 4.66667L6.72333 5.61L8.44667 7.33333H2V8.66667H8.44667L6.72333 10.39ZM12.6667 2H3.33333C2.59667 2 2 2.59667 2 3.33333V6H3.33333V3.33333H12.6667V12.6667H3.33333V10H2V12.6667C2 13.4033 2.59667 14 3.33333 14H12.6667C13.4033 14 14 13.4033 14 12.6667V3.33333C14 2.59667 13.4033 2 12.6667 2Z" fill="#2A2C36"/>
                </svg>
                <span>&nbsp;INFORMAÇÕES</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/admin/aplicacoes" class="nav-link">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.72333 10.39L7.66667 11.3333L11 8L7.66667 4.66667L6.72333 5.61L8.44667 7.33333H2V8.66667H8.44667L6.72333 10.39ZM12.6667 2H3.33333C2.59667 2 2 2.59667 2 3.33333V6H3.33333V3.33333H12.6667V12.6667H3.33333V10H2V12.6667C2 13.4033 2.59667 14 3.33333 14H12.6667C13.4033 14 14 13.4033 14 12.6667V3.33333C14 2.59667 13.4033 2 12.6667 2Z" fill="#2A2C36"/>
                </svg>
                <span>&nbsp;APLICAÇÕES</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/" class="nav-link">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.72333 10.39L7.66667 11.3333L11 8L7.66667 4.66667L6.72333 5.61L8.44667 7.33333H2V8.66667H8.44667L6.72333 10.39ZM12.6667 2H3.33333C2.59667 2 2 2.59667 2 3.33333V6H3.33333V3.33333H12.6667V12.6667H3.33333V10H2V12.6667C2 13.4033 2.59667 14 3.33333 14H12.6667C13.4033 14 14 13.4033 14 12.6667V3.33333C14 2.59667 13.4033 2 12.6667 2Z" fill="#2A2C36"/>
                </svg>
                <span>&nbsp;LOGOUT</span>
              </router-link>
            </li>
          </ul>
          <router-link to="/" class="btn btn-padrao btn-rosa btn-back mt-5">VOLTAR AO SITE</router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  padding: 50px 0;
  background-color: #fff;
  .navbar {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      margin-right: 82px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .nav-link {
    display: flex;
    align-items: center;
  }
}
.painel {
  font-size: 14px;
  font-weight: 700;
}

@media (min-width: 768px) {
  .header {
    padding: 50px 45px;
  }
}

@media (min-width: 1200px) {
  .header {
    padding: 69px 43px;
    width: 330px;
  }
  .navbar-nav {
    flex-direction: column !important;
  }
  #navbar {
    display: flex;
    flex-direction: column;
  }
  .header-wrapper {
    flex-direction: column;
  }
  .painel {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-right: auto;
  }
  .btn-back {
    margin: 0;
  }
  ul {
    li {
      margin-top: 25px;
    }
  }
}
</style>
