<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div id="nav">
            <div class="menu-wrapper">
              <router-link to="/equipe">EQUIPE</router-link>
              <span class="separator">/</span>
              <router-link to="/faq">FAQ</router-link>
              <span class="separator">/</span>
              <router-link to="/status">STATUS </router-link>
              <span class="separator d-none d-md-block">/</span>
              <router-link to="/termos" class="d-none d-md-block">TERMOS E CONDIÇÕES</router-link>
            </div>
            <div class="menu-wrapper mt-3 d-flex d-md-none">
              <router-link to="/termos">TERMOS E CONDIÇÕES</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="socials">
            <a href="https://twitter.com/palazegg" target="_blank" class="social">
              <img src="../assets/twitter.svg" alt="twitter">
            </a>
            <a href="#" target="_blank" class="social">
              <img src="../assets/telegram.svg" alt="telegram">
            </a>
            <a href="https://www.instagram.com/palazegg/" target="_blank" class="social">
              <img src="../assets/instagram.svg" alt="instagram">
            </a>
            <a href="#" target="_blank" class="social">
              <img src="../assets/github.svg" alt="github">
            </a>
            <a href="#" target="_blank" class="social">
              <img src="../assets/reddit.svg" alt="reddit">
            </a>
          </div>
          <div class="direitos-wrapper">
            <p class="direitos fonte-secundaria">© 2021 Palaze. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  background-color: #1D1534;
}
#nav {
  margin-top: 160px;
  margin-bottom: 121px;
  .menu-wrapper {
    display: flex;
    justify-content: center;
  }
  a {
    font-weight: 700;
    color: #fff;
    font-size: 16px;
    transition: 0.3s;
    &:hover {
      opacity: 0.5;
    }
  }
  .separator {
    font-weight: 700;
    font-size: 16px;
    margin: 0 15px;
  }
}
.socials {
  margin-bottom: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    margin: 0 10px;
    transition: 0.3s;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}
.direitos-wrapper {
  display: flex;
  justify-content: center;
  .direitos {
    font-size: 14px;
    color: #fff;
  }
}
</style>
