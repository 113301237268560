<template>
  <div class="noticias">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="noticias-titulo">
            <p class="ultimas">ÚLTIMAS</p>
            <h1 class="rosa-padrao-cor">NOTÍCIAS</h1>
          </div>
        </div>
      </div>
      <div class="row">
        <NoticiasComponent v-for="(noticia, index) in noticias" :key="index"/>
      </div>
      <div class="row">
        <div class="col-12 botao-wrapper">
          <button class="btn btn-padrao btn-rosa mt-5">CARREGAR MAIS</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.noticias {
  background-image: url("../assets/bg-pattern.png");
}
.botao-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 96px;
}
.noticias-titulo {
  .ultimas {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 13px;
    color: #000;
  }
  h1 {
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 0;
  }
}
</style>

<script>
import NoticiasComponent from '@/components/Noticias.vue'

export default {
  name: 'Noticias',
  data() {
    return {
      noticias: [{},{},{},{},{},{}]
    }
  },
  components: {
    NoticiasComponent
  }
}
</script>
