<template>
  <div class="modos-de-jogo">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="subtitulo">LOREM IPSUM</p>
          <h1 class="titulo rosa-padrao-cor">MODOS DE JOGO</h1>
          <p class="descricao mb-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed mattis tellus. Aliquam id dignissim elit. Ut eu est felis. Praesent accumsan, est et dignissim ornare, massa tellus condimentum neque, vitae rhoncus sem arcu at turpis. 

            Phasellus dapibus maximus ante sed sollicitudin. Duis ac nisl nec dui convallis ullamcorper. Suspendisse potenti. Integer nisi elit, mollis ac lacus at, ultricies porta dolor. Aenean ut risus sit amet leo dapibus placerat. Nullam sit amet ornare tellus, a pretium lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec sodales neque sit amet ullamcorper posuere. Mauris ultrices purus vitae aliquet porttitor. Morbi sollicitudin purus nec pellentesque iaculis. Vivamus vel neque a justo convallis rhoncus vitae et nisi.
          </p>
          <div class="container-grid">
            <div class="jogos-item">
              <p class="titulo-jogos">BED WARS ></p>
              <p class="online">Jogadores online:</p>
            </div>
            <div class="jogos-item">
              <p class="titulo-jogos">MODO DE JOGO ></p>
              <p class="online">Jogadores online:</p>
            </div>
            <div class="jogos-item">
              <p class="titulo-jogos">MODO DE JOGO ></p>
              <p class="online">Jogadores online:</p>
            </div>
            <div class="jogos-item">
              <p class="titulo-jogos">MODO DE JOGO ></p>
              <p class="online">Jogadores online:</p>
            </div>
            <div class="jogos-item">
              <p class="titulo-jogos">MODO DE JOGO ></p>
              <p class="online">Jogadores online:</p>
            </div>
            <div class="jogos-item">
              <p class="titulo-jogos">MODO DE JOGO ></p>
              <p class="online">Jogadores online:</p>
            </div>
            <div class="jogos-item">
              <p class="titulo-jogos">MODO DE JOGO ></p>
              <p class="online">Jogadores online:</p>
            </div>
            <div class="jogos-item">
              <p class="titulo-jogos">MODO DE JOGO ></p>
              <p class="online">Jogadores online:</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.subtitulo {
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 2px;
}
.titulo {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 50px;
}
.jogos-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  .jogos-item {
    aspect-ratio: 1;
    background-image: url('../assets/bed-wars.png');
    background-repeat: no-repeat;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    p {
      margin-bottom: 0;
      color: white;
    }
    .titulo-jogos {
      font-weight: 700;
      font-size: 22px;
    }
    .online {
      font-weight: 700;
    }
  }
}

@media (min-width: 768px) {
  .container-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 992px) {
  .container-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr ;
  }
}
</style>

<script>
export default {
  name: 'ModosDeJogo',
  data() {
    return {}
  },
  components: {
  }
}
</script>