<template>
    <div class="sticky-social">
        <a href="https://twitter.com/palazegg" target="_blank" class="twitter">
            <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.4585 0.00851319C18.6478 0.580375 17.7502 1.01776 16.8002 1.30382C16.2903 0.717553 15.6127 0.302024 14.859 0.11343C14.1052 -0.0751648 13.3118 -0.0277251 12.5859 0.249332C11.86 0.52639 11.2367 1.0197 10.8003 1.66254C10.3639 2.30538 10.1355 3.06673 10.1459 3.84363V4.69023C8.6581 4.72881 7.18386 4.39884 5.85446 3.72971C4.52507 3.06059 3.3818 2.07307 2.52646 0.855117C2.52646 0.855117 -0.859955 8.47455 6.75948 11.861C5.01592 13.0445 2.93889 13.6379 0.833252 13.5542C8.45268 17.7872 17.7653 13.5542 17.7653 3.81823C17.7645 3.58241 17.7419 3.34717 17.6976 3.11555C18.5616 2.26344 19.1714 1.18759 19.4585 0.00851319Z" fill="#151515"/>
            </svg>
        </a>
        <a href="https://www.instagram.com/palazegg/" target="_blank" class="instagram">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.7176 1H5.25157C2.91374 1 1.01855 2.89519 1.01855 5.23302V13.6991C1.01855 16.0369 2.91374 17.9321 5.25157 17.9321H13.7176C16.0554 17.9321 17.9506 16.0369 17.9506 13.6991V5.23302C17.9506 2.89519 16.0554 1 13.7176 1Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.871 8.93302C12.9754 9.6376 12.8551 10.3572 12.527 10.9894C12.199 11.6217 11.6799 12.1344 11.0437 12.4546C10.4074 12.7749 9.68642 12.8863 8.98318 12.7732C8.27994 12.66 7.63029 12.328 7.12663 11.8243C6.62296 11.3206 6.29094 10.671 6.17778 9.96775C6.06462 9.26451 6.17608 8.54349 6.49632 7.90725C6.81656 7.27101 7.32926 6.75195 7.9615 6.42389C8.59374 6.09583 9.31333 5.97549 10.0179 6.07997C10.7366 6.18654 11.402 6.52144 11.9157 7.0352C12.4295 7.54895 12.7644 8.21432 12.871 8.93302Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.1409 4.80969H14.1493" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </a>
        <a href="" target="_blank" class="reddit">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.0565 7.45688C17.4148 7.45688 16.8501 7.72695 16.4344 8.14771C14.9063 7.07422 12.8456 6.38339 10.5623 6.30974L11.7484 0.886394L15.5251 1.74654C15.5251 2.68458 16.2786 3.45076 17.2031 3.45076C18.1454 3.45076 18.9031 2.66426 18.9031 1.72538C18.9031 0.786495 18.1504 0 17.2039 0C16.5444 0 15.9755 0.40383 15.6919 0.955815L11.5207 0.0177787C11.3107 -0.0389437 11.105 0.113445 11.0491 0.326789L9.74706 6.3055C7.48155 6.40117 5.44293 7.09115 3.90973 8.16549C3.49405 7.72695 2.90735 7.45688 2.26563 7.45688C-0.115024 7.45688 -0.894746 10.6985 1.28526 11.8067C1.20822 12.1496 1.17351 12.5153 1.17351 12.8802C1.17351 16.5215 5.21604 19.4719 10.1797 19.4719C15.1645 19.4719 19.207 16.5215 19.207 12.8802C19.207 12.5153 19.1689 12.1327 19.0749 11.789C21.2109 10.6765 20.4244 7.45858 18.0565 7.45688ZM4.74448 12.037C4.74448 11.0812 5.49796 10.3116 6.44446 10.3116C7.36895 10.3116 8.12328 11.0761 8.12328 12.037C8.12328 12.9759 7.3698 13.7412 6.44446 13.7412C5.50219 13.7455 4.74448 12.9759 4.74448 12.037ZM13.9208 16.099C12.3622 17.6805 7.96411 17.6805 6.40467 16.099C6.23281 15.9466 6.23281 15.6774 6.40467 15.5039C6.55452 15.3515 6.82035 15.3515 6.9702 15.5039C8.16053 16.7424 12.1091 16.7636 13.3511 15.5039C13.5009 15.3515 13.7667 15.3515 13.9166 15.5039C14.0918 15.6783 14.0918 15.9475 13.9208 16.099ZM13.8861 13.7446C12.9616 13.7446 12.2081 12.9801 12.2081 12.0421C12.2081 11.0863 12.9616 10.3167 13.8861 10.3167C14.8284 10.3167 15.5861 11.0812 15.5861 12.0421C15.5819 12.9759 14.8284 13.7446 13.8861 13.7446Z" fill="#151515"/>
            </svg>
        </a>
    </div>
</template>

<script>
export default {
  name: 'Social'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .sticky-social {
        border-radius: 6px;
        background-color: #FFF;
        position: fixed;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px;
        flex-direction: column;
        a {
            transition: 0.3s;
        }
        a:not(:last-child) {
            margin-bottom: 20px;
        }
        a:hover {
            opacity: .5;
        }
    }
</style>