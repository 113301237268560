<template>
  <div class="header">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container-fluid">
        <router-link to="/" class="palaze-logo navbar-brand">
          <img src="../assets/palaze.svg" class="img-fluid" alt="Palaze logo">
        </router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse fonte-secundaria" id="navbar">
          <div class="ul-wrapper">
            <ul class="navbar-nav me-auto me-lg-0 mt-5 mt-lg-0">
              <li class="nav-item">
                <router-link to="/" class="nav-link active">Início</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/modos-de-jogo" class="nav-link">Jogos</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/noticias" class="nav-link">Notícias</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/ranking" class="nav-link">Placar</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/loja" class="nav-link">Loja</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/suporte" class="nav-link">Ajuda</router-link>
              </li>
            </ul>
          </div>
          <!-- <router-link to="/admin" class="user">
            <div class="user-image">
              <img src="../assets/palaze.svg" alt="Imagem do Usuário" class="rounded-circle">
            </div>
            <div class="user-name">
              <span>UNEPICKID</span>
              <div class="triangle">
                <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.5 5.26175L6.53109 0.134728L0.468911 0.134728L3.5 5.26175Z" fill="white"/>
                </svg>
              </div>
            </div>
          </router-link> -->
          <router-link to="/login" class="user">
            <div class="user-name">
              <span>ENTRAR</span>
            </div>
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header {
  padding: 50px 0;
  background-color: #1F2326;
  .navbar {
    background-color: #1F2326;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      color: #FFF;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
      margin-right: 82px;
    }
  }
  .user {
    display: flex;
    margin-top: 40px;
    .user-image {
      img {
        width: 42px;
        height: 42px;
        background-color: #C4C4C4
      }
    }
    .user-name {
      color: #FFF;
      font-weight: 700;
      font-size: 14px;
      display: flex;
      align-items: center;
      margin-left: 18px;

      .triangle {
        margin-left: 18px;
      }
    }
  }
}

@media (min-width: 768px) {
  .header {
    padding: 45px;
  }
}
@media (min-width: 992px) {
  .header {
    .navbar {
      #navbar {
        display: flex;
        justify-content: space-between;
        .ul-wrapper {
          margin: auto;
        }
      }
      a {
        margin-right: 0;
      }
      .user {
        margin-top: 0;
      }
    }
  }
}
@media (min-width: 1200px) {
  .nav-item a {
    transition: 0.3s;
  }
  .nav-item a:hover {
    opacity: .5;
  }
  .nav-item:not(:last-child) {
    margin-right: 60px;
  }
}
</style>
