import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Noticias from '../views/Noticias.vue'
import Noticia from '../views/Noticia.vue'
import ModosDeJogo from '../views/ModosDeJogo.vue'
import Ranking from '../views/Ranking.vue'
import Equipe from '../views/Equipe.vue'
import Suporte from '../views/Suporte.vue'
import Login from '../views/Login.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/noticias',
    name: 'Noticias',
    component: Noticias
  },
  {
    path: '/noticias/:id',
    name: 'Noticia',
    component: Noticia
  },
  {
    path: '/modos-de-jogo',
    name: 'ModosDeJogo',
    component: ModosDeJogo
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: Ranking
  },
  {
    path: '/equipe',
    name: 'Equipe',
    component: Equipe
  },
  {
    path: '/suporte',
    name: 'Suporte',
    component: Suporte
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/esqueci-a-senha',
    name: 'EsqueciSenha',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EsqueciSenha.vue')
  },
  {
    path: '/recuperar-senha',
    name: 'RecuperarSenha',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RecuperarSenha.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Admin.vue')
  },
  {
    path: '/admin/informacoes',
    name: 'Informacoes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Informacoes.vue')
  },
  {
    path: '/admin/aplicacoes',
    name: 'Aplicacoes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Aplicacoes.vue')
  },
  {
    path: '/admin/parceiro',
    name: 'Parceiro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Parceiro.vue')
  },
  {
    path: '/admin/moderacao',
    name: 'Moderacao',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/Moderacao.vue')
  },
  {
    path: '/admin/aplicacao-finalizada',
    name: 'AplicacaoFinalizada',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AplicacaoFinalizada.vue')
  },
  {
    path: '/primeiro-acesso/:token',
    name: 'PrimeiroAcesso',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PrimeiroAcesso.vue')
  },
  {
    path: '/conta-verificada',
    name: 'ContaVerificada',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContaVerificada.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
