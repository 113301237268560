<template>
  <div class="ranking">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="subtitulo-ranking">RANKING</p>
          <h1 class="titulo-ranking rosa-padrao-cor">TOP JOGADORES DO SERVIDOR</h1>
          <p class="descricao-ranking fonte-secundaria">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed mattis tellus. Aliquam id dignissim elit. Ut eu est felis. Praesent accumsan, est et dignissim ornare, massa tellus condimentum neque, vitae rhoncus sem arcu at turpis. 

            Phasellus dapibus maximus ante sed sollicitudin. Duis ac nisl nec dui convallis ullamcorper. Suspendisse potenti. Integer nisi elit, mollis ac lacus at, ultricies porta dolor. Aenean ut risus sit amet leo dapibus placerat. Nullam sit amet ornare tellus, a pretium lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec sodales neque sit amet ullamcorper posuere. Mauris ultrices purus vitae aliquet porttitor. Morbi sollicitudin purus nec pellentesque iaculis. Vivamus vel neque a justo convallis rhoncus vitae et nisi.
          </p>
          <div class="botoes-wrapper">
            <button class="btn btn-padrao btn-rosa">TEMPORADA 1
              <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.5 6L6.53109 0.872979L0.468911 0.872979L3.5 6Z" fill="white"/>
              </svg>
            </button>
            <button class="btn btn-padrao btn-preto">BUSCAR JOGADOR
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.7117 15.9131L13.0554 12.2569H13.016C14.143 10.9281 14.7125 9.21503 14.6054 7.47602C14.4983 5.737 13.723 4.10675 12.4415 2.92628C11.16 1.74581 9.47175 1.10657 7.7298 1.14229C5.98786 1.178 4.32719 1.8859 3.09519 3.1179C1.8632 4.3499 1.1553 6.01056 1.11958 7.75251C1.08387 9.49445 1.72311 11.1827 2.90357 12.4642C4.08404 13.7457 5.71429 14.521 7.45331 14.6281C9.19233 14.7352 10.9054 14.1657 12.2342 13.0387C12.2342 13.0387 12.2342 13.0669 12.2342 13.0781L15.8904 16.7344C15.9427 16.7871 16.0049 16.8289 16.0735 16.8575C16.142 16.8861 16.2155 16.9008 16.2898 16.9008C16.364 16.9008 16.4376 16.8861 16.5061 16.8575C16.5747 16.8289 16.6369 16.7871 16.6892 16.7344C16.7468 16.6833 16.7934 16.6209 16.826 16.5512C16.8586 16.4814 16.8765 16.4056 16.8786 16.3286C16.8807 16.2516 16.867 16.175 16.8382 16.1035C16.8095 16.0321 16.7664 15.9673 16.7117 15.9131ZM7.87479 13.5C6.76227 13.5 5.67473 13.1701 4.7497 12.552C3.82468 11.9339 3.10371 11.0554 2.67796 10.0276C2.25222 8.99975 2.14083 7.86875 2.35787 6.77761C2.57491 5.68647 3.11064 4.68419 3.89731 3.89752C4.68398 3.11085 5.68626 2.57512 6.7774 2.35807C7.86855 2.14103 8.99955 2.25243 10.0274 2.67817C11.0552 3.10391 11.9337 3.82488 12.5518 4.74991C13.1699 5.67494 13.4998 6.76247 13.4998 7.87499C13.4998 8.61368 13.3543 9.34513 13.0716 10.0276C12.7889 10.71 12.3746 11.3301 11.8523 11.8525C11.3299 12.3748 10.7098 12.7891 10.0274 13.0718C9.34492 13.3545 8.61347 13.5 7.87479 13.5Z" fill="white"/>
              </svg>
            </button>
          </div>
          <table class="table mt-5">
            <thead>
              <tr>
                <th scope="col">POS.</th>
                <th scope="col">JOGADOR</th>
                <th scope="col">ELO</th>
                <th scope="col">PONTOS</th>
              </tr>
            </thead>
            <tbody>
              <tr class="primeiro-separador">
                <th></th>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr class="jogador-item rosa">
                <th scope="row">1</th>
                <td>
                  <div class="jogador-wrapper">
                    <img src="../assets/avatar.png" alt="Jessie Clarcson" class="img-fluid d-none d-md-block">
                    <span>Jessie Clarcson</span>
                  </div>
                </td>
                <td>
                  <div class="elo-wrapper">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.49995 12.7501L5.60685 14.2711C5.26058 14.4531 4.83231 14.32 4.65026 13.9737C4.57777 13.8358 4.55276 13.6779 4.57909 13.5244L5.13163 10.3028L2.79106 8.02134C2.51092 7.74828 2.50519 7.29982 2.77825 7.01969C2.88699 6.90814 3.02947 6.83554 3.18362 6.81314L6.41821 6.34313L7.86476 3.4121C8.0379 3.06129 8.46263 2.91726 8.81344 3.09039C8.95313 3.15934 9.0662 3.2724 9.13514 3.4121L10.5817 6.34313L13.8163 6.81314C14.2034 6.8694 14.4716 7.22884 14.4154 7.61597C14.393 7.77013 14.3204 7.91261 14.2088 8.02134L11.8683 10.3028L12.4208 13.5244C12.4869 13.9099 12.228 14.2761 11.8424 14.3422C11.6889 14.3686 11.5309 14.3436 11.3931 14.2711L8.49995 12.7501Z" fill="#FFA800"/>
                    </svg>
                    <span>Platina</span>
                  </div>
                </td>
                <td>1000</td>
              </tr>
              <tr class="separador">
                <th></th>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr class="jogador-item rosa">
                <th scope="row">2</th>
                <td>
                  <div class="jogador-wrapper">
                    <img src="../assets/avatar.png" alt="Jessie Clarcson" class="img-fluid d-none d-md-block">
                    <span>Jessie Clarcson</span>
                  </div>
                </td>
                <td>
                  <div class="elo-wrapper">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.49995 12.7501L5.60685 14.2711C5.26058 14.4531 4.83231 14.32 4.65026 13.9737C4.57777 13.8358 4.55276 13.6779 4.57909 13.5244L5.13163 10.3028L2.79106 8.02134C2.51092 7.74828 2.50519 7.29982 2.77825 7.01969C2.88699 6.90814 3.02947 6.83554 3.18362 6.81314L6.41821 6.34313L7.86476 3.4121C8.0379 3.06129 8.46263 2.91726 8.81344 3.09039C8.95313 3.15934 9.0662 3.2724 9.13514 3.4121L10.5817 6.34313L13.8163 6.81314C14.2034 6.8694 14.4716 7.22884 14.4154 7.61597C14.393 7.77013 14.3204 7.91261 14.2088 8.02134L11.8683 10.3028L12.4208 13.5244C12.4869 13.9099 12.228 14.2761 11.8424 14.3422C11.6889 14.3686 11.5309 14.3436 11.3931 14.2711L8.49995 12.7501Z" fill="#FFA800"/>
                    </svg>
                    <span>Platina</span>
                  </div>
                </td>
                <td>1000</td>
              </tr>
              <tr class="separador">
                <th></th>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr class="jogador-item rosa">
                <th scope="row">3</th>
                <td>
                  <div class="jogador-wrapper">
                    <img src="../assets/avatar.png" alt="Jessie Clarcson" class="img-fluid d-none d-md-block">
                    <span>Jessie Clarcson</span>
                  </div>
                </td>
                <td>
                  <div class="elo-wrapper">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.49995 12.7501L5.60685 14.2711C5.26058 14.4531 4.83231 14.32 4.65026 13.9737C4.57777 13.8358 4.55276 13.6779 4.57909 13.5244L5.13163 10.3028L2.79106 8.02134C2.51092 7.74828 2.50519 7.29982 2.77825 7.01969C2.88699 6.90814 3.02947 6.83554 3.18362 6.81314L6.41821 6.34313L7.86476 3.4121C8.0379 3.06129 8.46263 2.91726 8.81344 3.09039C8.95313 3.15934 9.0662 3.2724 9.13514 3.4121L10.5817 6.34313L13.8163 6.81314C14.2034 6.8694 14.4716 7.22884 14.4154 7.61597C14.393 7.77013 14.3204 7.91261 14.2088 8.02134L11.8683 10.3028L12.4208 13.5244C12.4869 13.9099 12.228 14.2761 11.8424 14.3422C11.6889 14.3686 11.5309 14.3436 11.3931 14.2711L8.49995 12.7501Z" fill="#FFA800"/>
                    </svg>
                    <span>Ouro</span>
                  </div>
                </td>
                <td>1000</td>
              </tr>
              <tr class="separador">
                <th></th>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr class="jogador-item cinza">
                <th scope="row">4</th>
                <td>
                  <div class="jogador-wrapper">
                    <img src="../assets/avatar.png" alt="Jessie Clarcson" class="img-fluid d-none d-md-block">
                    <span>Jessie Clarcson</span>
                  </div>
                </td>
                <td>
                  <div class="elo-wrapper">
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.49995 12.7501L5.60685 14.2711C5.26058 14.4531 4.83231 14.32 4.65026 13.9737C4.57777 13.8358 4.55276 13.6779 4.57909 13.5244L5.13163 10.3028L2.79106 8.02134C2.51092 7.74828 2.50519 7.29982 2.77825 7.01969C2.88699 6.90814 3.02947 6.83554 3.18362 6.81314L6.41821 6.34313L7.86476 3.4121C8.0379 3.06129 8.46263 2.91726 8.81344 3.09039C8.95313 3.15934 9.0662 3.2724 9.13514 3.4121L10.5817 6.34313L13.8163 6.81314C14.2034 6.8694 14.4716 7.22884 14.4154 7.61597C14.393 7.77013 14.3204 7.91261 14.2088 8.02134L11.8683 10.3028L12.4208 13.5244C12.4869 13.9099 12.228 14.2761 11.8424 14.3422C11.6889 14.3686 11.5309 14.3436 11.3931 14.2711L8.49995 12.7501Z" fill="#FFA800"/>
                    </svg>
                    <span>Bronze</span>
                  </div>
                </td>
                <td>1000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.subtitulo-ranking {
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 13px;
}
.titulo-ranking {
  font-weight: 700;
  font-size: 48px;
  margin-bottom: 24px;
}
.botoes-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
.table {
  thead {
    background-color: #000;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    height: 43px;
    vertical-align: middle;
  }
  tbody {
    .jogador-item {
      height: 63px;
    }
    .rosa {
      background-color: #E4157D;
      color: #fff;
    }
    .cinza {
      background-color: #EBEBEB;
      color: #000;
    }
    .primeiro-separador {
      height: 21px;
    }
    .separador {
      height: 15px;
    }
    th, td {
      border: inherit;
      vertical-align: middle;
    }
    .jogador-wrapper {
      display: flex;
      align-items: center;
      span {
        padding-left: 10px;
      }
    }
    .elo-wrapper {
      display: flex;
      align-items: center;
    }
  }
}
.table tr th:last-child{
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding-right: 28px;
}
.table tr th:first-child{
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  padding-left: 28px;
}
.table tr td:last-child{
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding-right: 28px;
}
</style>

<script>
export default {
  name: 'Ranking',
  data() {
    return {}
  },
  components: {
  }
}
</script>