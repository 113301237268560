<template>
  <div class="noticia">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="imagem-noticia">
            <img src="https://picsum.photos/1500/1500" alt="Imagem da noticia" class="img-fluid">
          </div>
        </div>
        <div class="col-12">
          <h1>NOTAS DE ATUALIZAÇÃO</h1>
          <p class="data fonte-secundaria">September 25th, 2018 • Update</p>
          <p class="compartilhar">Compartilhar</p>
          <div class="compartilhar-wrapper">
            <div class="compartilhar-item">
              <div class="icone">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.9999 2C10.6866 2 9.38629 2.25866 8.17303 2.7612C6.95978 3.26375 5.85738 4.00035 4.9288 4.92893C3.05343 6.8043 1.99987 9.34784 1.99987 12C1.99112 14.3091 2.79066 16.5485 4.25987 18.33L2.25987 20.33C2.12111 20.4706 2.02711 20.6492 1.98974 20.8432C1.95236 21.0372 1.97329 21.2379 2.04987 21.42C2.13292 21.5999 2.26757 21.7511 2.43671 21.8544C2.60586 21.9577 2.80187 22.0083 2.99987 22H11.9999C14.652 22 17.1956 20.9464 19.0709 19.0711C20.9463 17.1957 21.9999 14.6522 21.9999 12C21.9999 9.34784 20.9463 6.8043 19.0709 4.92893C17.1956 3.05357 14.652 2 11.9999 2ZM11.9999 20H5.40987L6.33987 19.07C6.52612 18.8826 6.63066 18.6292 6.63066 18.365C6.63066 18.1008 6.52612 17.8474 6.33987 17.66C5.03045 16.352 4.21504 14.6305 4.03256 12.7888C3.85007 10.947 4.31181 9.09901 5.33909 7.55952C6.36638 6.02004 7.89566 4.88436 9.66638 4.34597C11.4371 3.80759 13.3397 3.8998 15.0501 4.60691C16.7604 5.31402 18.1727 6.59227 19.0463 8.22389C19.9199 9.85551 20.2007 11.7395 19.841 13.555C19.4813 15.3705 18.5032 17.005 17.0734 18.1802C15.6436 19.3554 13.8506 19.9985 11.9999 20Z" fill="#423B3B"/>
                </svg>
              </div>
              <p class="titulo-botao fonte-secundaria">Chat</p>
            </div>
            <div class="compartilhar-item">
              <div class="icone">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.9999 2C10.6866 2 9.38629 2.25866 8.17303 2.7612C6.95978 3.26375 5.85738 4.00035 4.9288 4.92893C3.05343 6.8043 1.99987 9.34784 1.99987 12C1.99112 14.3091 2.79066 16.5485 4.25987 18.33L2.25987 20.33C2.12111 20.4706 2.02711 20.6492 1.98974 20.8432C1.95236 21.0372 1.97329 21.2379 2.04987 21.42C2.13292 21.5999 2.26757 21.7511 2.43671 21.8544C2.60586 21.9577 2.80187 22.0083 2.99987 22H11.9999C14.652 22 17.1956 20.9464 19.0709 19.0711C20.9463 17.1957 21.9999 14.6522 21.9999 12C21.9999 9.34784 20.9463 6.8043 19.0709 4.92893C17.1956 3.05357 14.652 2 11.9999 2ZM11.9999 20H5.40987L6.33987 19.07C6.52612 18.8826 6.63066 18.6292 6.63066 18.365C6.63066 18.1008 6.52612 17.8474 6.33987 17.66C5.03045 16.352 4.21504 14.6305 4.03256 12.7888C3.85007 10.947 4.31181 9.09901 5.33909 7.55952C6.36638 6.02004 7.89566 4.88436 9.66638 4.34597C11.4371 3.80759 13.3397 3.8998 15.0501 4.60691C16.7604 5.31402 18.1727 6.59227 19.0463 8.22389C19.9199 9.85551 20.2007 11.7395 19.841 13.555C19.4813 15.3705 18.5032 17.005 17.0734 18.1802C15.6436 19.3554 13.8506 19.9985 11.9999 20Z" fill="#423B3B"/>
                </svg>
              </div>
              <p class="titulo-botao fonte-secundaria">Chat</p>
            </div>
            <div class="compartilhar-item">
              <div class="icone">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.9999 2C10.6866 2 9.38629 2.25866 8.17303 2.7612C6.95978 3.26375 5.85738 4.00035 4.9288 4.92893C3.05343 6.8043 1.99987 9.34784 1.99987 12C1.99112 14.3091 2.79066 16.5485 4.25987 18.33L2.25987 20.33C2.12111 20.4706 2.02711 20.6492 1.98974 20.8432C1.95236 21.0372 1.97329 21.2379 2.04987 21.42C2.13292 21.5999 2.26757 21.7511 2.43671 21.8544C2.60586 21.9577 2.80187 22.0083 2.99987 22H11.9999C14.652 22 17.1956 20.9464 19.0709 19.0711C20.9463 17.1957 21.9999 14.6522 21.9999 12C21.9999 9.34784 20.9463 6.8043 19.0709 4.92893C17.1956 3.05357 14.652 2 11.9999 2ZM11.9999 20H5.40987L6.33987 19.07C6.52612 18.8826 6.63066 18.6292 6.63066 18.365C6.63066 18.1008 6.52612 17.8474 6.33987 17.66C5.03045 16.352 4.21504 14.6305 4.03256 12.7888C3.85007 10.947 4.31181 9.09901 5.33909 7.55952C6.36638 6.02004 7.89566 4.88436 9.66638 4.34597C11.4371 3.80759 13.3397 3.8998 15.0501 4.60691C16.7604 5.31402 18.1727 6.59227 19.0463 8.22389C19.9199 9.85551 20.2007 11.7395 19.841 13.555C19.4813 15.3705 18.5032 17.005 17.0734 18.1802C15.6436 19.3554 13.8506 19.9985 11.9999 20Z" fill="#423B3B"/>
                </svg>
              </div>
              <p class="titulo-botao fonte-secundaria">Chat</p>
            </div>
          </div>
          <p class="descricao fonte-secundaria">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque tincidunt dapibus scelerisque. Nullam vitae nulla leo. Donec tempus lorem interdum, euismod ipsum sed, mattis leo. Sed tellus turpis, ultrices nec feugiat eu, tempor at sem. Phasellus massa arcu, porta vel dui ut, rutrum sodales erat. Sed tincidunt tortor ac nisl scelerisque, nec iaculis ipsum ultricies. Suspendisse vitae efficitur diam, a posuere metus. Vestibulum tempus auctor mi, a lacinia quam maximus vitae. Etiam in viverra ligula, eget efficitur magna. Phasellus sit amet nisl orci. Vivamus pellentesque, nisi at auctor laoreet, velit neque interdum risus, eu commodo lectus nulla id leo. Cras elit orci, bibendum sed tincidunt et, vulputate ac urna. Maecenas sit amet urna tempor, blandit purus non, cursus tortor. Vivamus nec velit vel leo mattis dapibus.

            Curabitur at velit eu velit congue accumsan. In tempus tortor eget odio rhoncus, quis lobortis justo porta. Aliquam id felis velit. Aliquam erat volutpat. Nulla facilisi. Mauris imperdiet pretium malesuada. Praesent id nibh vel velit commodo fringilla.

            Curabitur facilisis, nunc eu tempor efficitur, est est dignissim quam, ac malesuada urna dui id odio. Etiam tempor tortor eu purus iaculis fringilla vitae sed metus. Nunc semper dapibus urna, nec rhoncus neque suscipit id. Morbi cursus venenatis euismod. Sed convallis blandit nisi, id sollicitudin elit condimentum sit amet. Donec viverra lorem ac arcu eleifend, luctus ultricies sem volutpat. Donec porttitor eleifend felis in cursus. Nunc efficitur erat vestibulum tristique iaculis. Maecenas at aliquam arcu. Praesent eu nisl at orci elementum cursus.

            Vestibulum pellentesque orci quis ante tempus rhoncus. Cras vel tortor at nisi consequat egestas. Nullam tristique euismod eros, vel eleifend magna ultricies vitae. Proin eget lectus maximus, vestibulum turpis non, dignissim justo. Nam sed ornare arcu. Curabitur lorem magna, blandit eu sem et, dictum varius magna. Aenean blandit orci vitae orci pretium, nec vestibulum leo placerat. Mauris orci purus, congue eu congue quis, cursus id tortor. Cras bibendum ex id velit vulputate porta. Sed nisl nulla, pretium eget enim ut, auctor dignissim tortor.

            Fusce vel mollis tellus. Ut at convallis nunc. Donec aliquam dapibus velit, at egestas tellus tristique a. Sed ullamcorper ac urna sed placerat. Curabitur sed congue sapien. Suspendisse imperdiet, nulla ac luctus volutpat, dui nunc hendrerit metus, vitae tempor neque ligula in orci. Donec volutpat risus eu odio maximus, sed venenatis leo iaculis. Quisque leo velit, ultrices eu ex eu, convallis eleifend turpis. Aenean et venenatis sem. Integer non diam dapibus, convallis lacus ut, interdum augue. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam convallis iaculis bibendum. Cras libero nulla, pulvinar ut mollis vel, vehicula id ex. Nam iaculis convallis suscipit. Sed sem ligula, varius sed pellentesque et, consequat non magna. Morbi nec dui et urna tincidunt commodo.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="noticias-titulo-wrapper">
            <p>Artigos Relacionados</p>
            <router-link to="/noticias" class="router-link">NOTÍCIAS ></router-link>
          </div>
        </div>
      </div>
      <div class="row">
        <NoticiasComponent v-for="(noticia, index) in noticias" :key="index"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.noticia {
  h1 {
    font-weight: 700;
    font-size: 28px;
  }
  .data {
    font-size: 14px;
  }
  .compartilhar {
    font-weight: 700;
    font-size: 14px;
    color: #000;
    text-decoration: none;
  }
  .descricao {
    margin-top: 40px;
  }
}
.imagem-noticia {
  margin-bottom: 67px;
}
.noticias-titulo-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 125px;
  p {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0;
  }
  a {
    font-weight: 600;
    color: #000;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
  }
}
.compartilhar-wrapper {
  display: flex;
  .compartilhar-item {
    margin-right: 15px;
    cursor: pointer;
    .icone {
      width: 72px;
      height: 72px;
      background-color: #9D9D9D0A;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
    }
    .titulo-botao {
      font-size: 12px;
      margin-bottom: 0;
      text-align: center;
      margin-top: 10px;
    }
  }
}

@media (min-width: 768px) {
}
</style>

<script>
import NoticiasComponent from '@/components/Noticias.vue'
export default {
  name: 'Noticia',
  data() {
    return {
      noticias: [{},{},{}]
    }
  },
  components: {
    NoticiasComponent
  }
}
</script>