<template>
  <router-link to="/noticias/1" class="noticia col-6 col-sm-6 col-md-4">
    <div class="imagem-noticia">
      <img src="https://picsum.photos/400/400" alt="Imagem da noticia" class="img-fluid">
    </div>
    <div class="noticia-header">
      <span class="categoria rosa-claro-cor">Update</span>
      <span>5 mins read</span>
    </div>
    <p class="noticia-titulo">Notas de Atualização - 0.1</p>
  </router-link>
</template>

<script>
export default {
  name: 'NoticiasComponent'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.noticia {
  text-decoration: none;
  color: inherit;
  .imagem-noticia {
    margin-top: 60px;
    margin-bottom: 28px;
  }
  .noticia-header {
    margin-bottom: 19px;
    font-size: 13px;
  }
  .noticia-titulo {
    color: #121212;
    font-weight: 700;
  }
  .categoria {
    font-weight: 500;
    margin-right: 15px;
  }
}
</style>
