<template>
  <div class="home">
    <div class="bem-vindo">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 col-xl-5 wrapper-bem-vindo">
            <p class="subtitulo-bem-vindo rosa-padrao-cor pb-3">Bem vindo ao Palaze</p>
            <h1 class="titulo-bem-vindo">USE SUA CRIATIVIDADE</h1>
            <p class="descricao-bem-vindo fonte-secundaria pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum interdum nulla elit. Fusce vitae mattis enim. Vestibulum viverra turpis vitae sem vulputate imperdiet. Mauris malesuada malesuada libero sed pulvinar. </p>
            <button class="btn btn-padrao btn-rosa mt-4">COPIAR IP</button>
          </div>
          <div class="d-none d-md-flex col-12 col-lg-6 col-xl-7 wrapper-vip">
            <img src="../assets/vip-minecraft.png" alt="vip minecraft skin" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
    <div class="video">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="ratio ratio-16x9 video-minecraft">
              <iframe src="https://www.youtube.com/embed/MmB9b5njVbA?rel=0" title="YouTube video" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="noticias">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="noticias-titulo-wrapper">
              <p>Últimas Noticias</p>
              <router-link to="/noticias" class="router-link">NOTÍCIAS ></router-link>
            </div>
          </div>
        </div>
        <div class="row">
          <NoticiasComponent v-for="(noticia, index) in noticias" :key="index"/>
        </div>
      </div>
    </div>
    <div class="como-jogar">
      <div class="header-wrapper">
        <img src="../assets/header-como-jogar.png" alt="Header como jogar" class="w-100">
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="como-jogar-wrapper">
              <img src="../assets/palaze_logo_icon.png" alt="palaze-logo" class="img-fluid">
              <h2>Como Jogar?</h2>
              <p class="fonte-secundaria">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              <span class="jogadores-online">XXXX jogadores online</span>
            </div>
          </div>
          <div class="col-12 col-lg-6 video-como-jogar-wrapper">
            <div class="ratio ratio-16x9">
              <iframe src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" title="YouTube video" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-wrapper">
        <img src="../assets/footer-como-jogar.png" alt="Footer como jogar" class="w-100">
      </div>
    </div>
    <div class="nosso-discord">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="discord-minecraft">
              <img src="../assets/discord_minecraft.png" alt="palaze-logo" class="img-fluid">
            </div>
          </div>
          <div class="col-12 col-md-6 wrapper-sobre-discord">
            <h2 class="mt-lg-5">NOSSO DISCORD</h2>
            <p class="fonte-secundaria">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            <button class="btn btn-padrao btn-branco mt-4">ACESSAR DISCORD</button>
          </div>
        </div>
      </div>
      <div class="wrapper-discord">
        <img src="../assets/footer-discord.png" alt="Footer nosso discord" class="w-100">
      </div>
    </div>
    <div class="time">
      <div class="container">
        <div class="row">
          <div class="col-12 mt-5">
            <p class="subtitulo">TIME</p>
            <h2 class="rosa-padrao-cor">NOSSOS ADMINS</h2>
            <div id="carouselTime" class="carousel carousel-dark slide mt-5 " data-bs-ride="carousel">
              <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselTime" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 0"></button>
                <button type="button" data-bs-target="#carouselTime" data-bs-slide-to="1" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselTime" data-bs-slide-to="2" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselTime" data-bs-slide-to="3" aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselTime" data-bs-slide-to="4" aria-label="Slide 4"></button>
                <button type="button" data-bs-target="#carouselTime" data-bs-slide-to="5" aria-label="Slide 5"></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="container px-0">
                    <div class="row">
                      <div class="col-12 col-md-6 equipe-imagem-wrapper">
                        <img src="../assets/equipe/Ryanziin.png" class="d-block w-100" alt="Personagem do time">
                      </div>
                      <div class="col-12 col-md-6 my-5 px-lg-5 equipe-info-wrapper">
                        <h3>NOME - FUNÇÃO</h3>
                        <p class="fonte-secundaria">Phasellus dapibus maximus ante sed sollicitudin. Duis ac nisl nec dui convallis ullamcorper. Suspendisse potenti. Integer nisi elit, mollis ac lacus at, ultricies porta dolor. Aenean ut risus sit amet leo dapibus placerat. Nullam sit amet ornare tellus, a pretium lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec sodales neque sit amet ullamcorper posuere. Mauris ultrices purus vitae aliquet porttitor. Morbi sollicitudin purus nec pellentesque iaculis. Vivamus vel neque a justo convallis rhoncus vitae et nisi.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="container px-0">
                    <div class="row">
                      <div class="col-12 col-md-6 equipe-imagem-wrapper">
                        <img src="../assets/equipe/iGuh.png" class="d-block w-100" alt="Personagem do time">
                      </div>
                      <div class="col-12 col-md-6 my-5 px-lg-5 equipe-info-wrapper">
                        <h3>NOME - FUNÇÃO</h3>
                        <p class="fonte-secundaria">Phasellus dapibus maximus ante sed sollicitudin. Duis ac nisl nec dui convallis ullamcorper. Suspendisse potenti. Integer nisi elit, mollis ac lacus at, ultricies porta dolor. Aenean ut risus sit amet leo dapibus placerat. Nullam sit amet ornare tellus, a pretium lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec sodales neque sit amet ullamcorper posuere. Mauris ultrices purus vitae aliquet porttitor. Morbi sollicitudin purus nec pellentesque iaculis. Vivamus vel neque a justo convallis rhoncus vitae et nisi.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="container px-0">
                    <div class="row">
                      <div class="col-12 col-md-6 equipe-imagem-wrapper">
                        <img src="../assets/equipe/MrLucas.png" class="d-block w-100" alt="Personagem do time">
                      </div>
                      <div class="col-12 col-md-6 my-5 px-lg-5 equipe-info-wrapper">
                        <h3>NOME - FUNÇÃO</h3>
                        <p class="fonte-secundaria">Phasellus dapibus maximus ante sed sollicitudin. Duis ac nisl nec dui convallis ullamcorper. Suspendisse potenti. Integer nisi elit, mollis ac lacus at, ultricies porta dolor. Aenean ut risus sit amet leo dapibus placerat. Nullam sit amet ornare tellus, a pretium lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec sodales neque sit amet ullamcorper posuere. Mauris ultrices purus vitae aliquet porttitor. Morbi sollicitudin purus nec pellentesque iaculis. Vivamus vel neque a justo convallis rhoncus vitae et nisi.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="container px-0">
                    <div class="row">
                      <div class="col-12 col-md-6 equipe-imagem-wrapper">
                        <img src="../assets/equipe/oThiago.png" class="d-block w-100" alt="Personagem do time">
                      </div>
                      <div class="col-12 col-md-6 my-5 px-lg-5 equipe-info-wrapper">
                        <h3>NOME - FUNÇÃO</h3>
                        <p class="fonte-secundaria">Phasellus dapibus maximus ante sed sollicitudin. Duis ac nisl nec dui convallis ullamcorper. Suspendisse potenti. Integer nisi elit, mollis ac lacus at, ultricies porta dolor. Aenean ut risus sit amet leo dapibus placerat. Nullam sit amet ornare tellus, a pretium lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec sodales neque sit amet ullamcorper posuere. Mauris ultrices purus vitae aliquet porttitor. Morbi sollicitudin purus nec pellentesque iaculis. Vivamus vel neque a justo convallis rhoncus vitae et nisi.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="container px-0">
                    <div class="row">
                      <div class="col-12 col-md-6 equipe-imagem-wrapper">
                        <img src="../assets/equipe/torvvic.png" class="d-block w-100" alt="Personagem do time">
                      </div>
                      <div class="col-12 col-md-6 my-5 px-lg-5 equipe-info-wrapper">
                        <h3>NOME - FUNÇÃO</h3>
                        <p class="fonte-secundaria">Phasellus dapibus maximus ante sed sollicitudin. Duis ac nisl nec dui convallis ullamcorper. Suspendisse potenti. Integer nisi elit, mollis ac lacus at, ultricies porta dolor. Aenean ut risus sit amet leo dapibus placerat. Nullam sit amet ornare tellus, a pretium lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec sodales neque sit amet ullamcorper posuere. Mauris ultrices purus vitae aliquet porttitor. Morbi sollicitudin purus nec pellentesque iaculis. Vivamus vel neque a justo convallis rhoncus vitae et nisi.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="container px-0">
                    <div class="row">
                      <div class="col-12 col-md-6 equipe-imagem-wrapper">
                        <img src="../assets/equipe/Zacky.png" class="d-block w-100" alt="Personagem do time">
                      </div>
                      <div class="col-12 col-md-6 my-5 px-lg-5 equipe-info-wrapper">
                        <h3>NOME - FUNÇÃO</h3>
                        <p class="fonte-secundaria">Phasellus dapibus maximus ante sed sollicitudin. Duis ac nisl nec dui convallis ullamcorper. Suspendisse potenti. Integer nisi elit, mollis ac lacus at, ultricies porta dolor. Aenean ut risus sit amet leo dapibus placerat. Nullam sit amet ornare tellus, a pretium lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec sodales neque sit amet ullamcorper posuere. Mauris ultrices purus vitae aliquet porttitor. Morbi sollicitudin purus nec pellentesque iaculis. Vivamus vel neque a justo convallis rhoncus vitae et nisi.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button class="d-none d-lg-block carousel-control-prev" type="button" data-bs-target="#carouselTime" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="d-none d-lg-block carousel-control-next" type="button" data-bs-target="#carouselTime" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoticiasComponent from '@/components/Noticias.vue'
export default {
  name: 'Home',
  data() {
    return {
      noticias: [
        {
          img: "www.image.com",
          categoria: "Update",
          time: "5 mins read",
          titulo: "NOTAS DE ATUALIZAÇÃO - 0.1"
        },
        {
          img: "www.image.com",
          categoria: "Update",
          time: "5 mins read",
          titulo: "NOTAS DE ATUALIZAÇÃO - 0.1"
        },
        {
          img: "www.image.com",
          categoria: "Update",
          time: "5 mins read",
          titulo: "NOTAS DE ATUALIZAÇÃO - 0.1"
        },
        {
          img: "www.image.com",
          categoria: "Update",
          time: "5 mins read",
          titulo: "NOTAS DE ATUALIZAÇÃO - 0.1"
        },
        {
          img: "www.image.com",
          categoria: "Update",
          time: "5 mins read",
          titulo: "NOTAS DE ATUALIZAÇÃO - 0.1"
        },
        {
          img: "www.image.com",
          categoria: "Update",
          time: "5 mins read",
          titulo: "NOTAS DE ATUALIZAÇÃO - 0.1"
        }
      ]
    }
  },
  components: {
    NoticiasComponent
  }
}
</script>

<style lang="scss" scoped>
.video-minecraft {
  margin-top: 100px;
}
.equipe-info-wrapper {
  p {
    margin-bottom: 0;
  }
}
.noticias-titulo-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
  p {
    font-weight: 600;
    color: #0F1935;
    margin: 0;
    font-size: 24px;
  }
  .router-link {
    color: #0F1935;
    font-weight: 600;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
    }
  }
}
.bem-vindo {
  .subtitulo-bem-vindo {
    font-weight: 600;
    font-size: 18px;
  }
  .titulo-bem-vindo {
    font-weight: 900 !important;
    font-size: 48px !important;
  }
  .descricao-bem-vindo {
    font-weight: 300;
    font-size: 16px;
  }
}

.noticias {
  /* background-image: url("../assets/bg-pattern.png"); */
}

.como-jogar {
  color: #FFF;
  background-color: #E4157D;
  .container {
    padding-bottom: 50px;
  }
  .header-wrapper {
    background-color: #FFF;
    background-position: 1px -40px;
    /* background-image: url("../assets/bg-pattern.png"); */
  }
  .jogadores-online {
    font-weight: 700;
    margin-bottom: 30px;
    display: block;
  }
  .footer-wrapper {
    background-color: #849FFE;
  }
  h2 {
    font-size: 48px;
    font-weight: 700;
    margin-top: 43px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    margin: 30px 0;
  }
}
.discord-minecraft {
  display: flex;
  justify-content: center;
  img {
    width: 40%;
    margin-bottom: 50px;
  }
}
.nosso-discord {
  background-color: #849FFE;
  color: white;
  .container {
    padding: 50px 15px;
  }
  h2 {
    font-size: 36px;
    font-weight: 700;
    font-weight: 500;
  }
  .wrapper-sobre-discord {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .wrapper-discord {
    background-color: #FFF;
  }
}

.time {
  .subtitulo {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 2px;
  }
  h2 {
    margin-bottom: 0;
    font-size: 48px;
    font-weight: 700;
  }
}

#carouselTime {
  h3 {
    font-weight: 700;
    font-size: 13px;
  }
  p {
    font-weight: 500;
    font-size: 16px;
  }
}

.equipe-imagem-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .video-minecraft {
    margin-top: inherit;
  }
  .wrapper-vip {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    img {
      margin-bottom: -75px;
    }
  }
  .video-como-jogar-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .discord-minecraft {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      width: 70%;
      position: absolute;
      top: 0;
    }
  }
}

@media (min-width: 992px) {
  .wrapper-vip {
    img {
      min-width: 160%;
      margin-bottom: -70px;
    }
  }
  .noticias {
    /* background-image: url("../assets/bg-pattern.png"); */
    margin-bottom: 50px;
  }
  .discord-minecraft {
    img {
      width: 80%;
    }
  }
}

@media (min-width: 1200px) {
  .home {
    background-image: url("../assets/bg-bem-vindo.png");
    background-repeat: no-repeat;
    background-position: 400px top;
  }
  .wrapper-bem-vindo {
    margin-top: 190px;
  }
  .wrapper-vip {
    display: flex;
    align-items: center;
    position: relative;
    img {
      position: absolute;
      min-width: 140%;
      margin-bottom: -325px;
    }
  }
  .noticias-titulo-wrapper {
    margin: 170px 0 0 0;
    p {
      font-size: 24px;
    }
    .router-link {
      font-size: 24px;
    }
  }
  .video-minecraft {
    margin-top: 100px;
  }
  .discord-minecraft {
    img {
      width: 60%;
    }
  }
  .carousel-item {
    padding: 50px 100px;
  }
}

@media (min-width: 1900px) {
  .home {
    background-position: right top;
  }
}
</style>